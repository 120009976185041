import React from 'react'
import Layout from '../components/Layout'
import TwitterLink from '../components/TwitterLink'
import {Link} from 'gatsby';

const CreditsPage = () => (
  <Layout pageTitle="Credits">
    <div style={{background: "#5abcff", height: 30}}>
    </div>
    <main className="Content">
      <div className="wrap" style={{maxWidth: 700}}>
        <h1>Credits for Danger Crew </h1>
        <p>
          Danger Crew was made by <strong>Drew Conley</strong> (<TwitterLink account="drewconley13"/>) and <strong>Glenn LaBarre</strong> (<TwitterLink account="gwlabarre"/>). Drew did the programming, art, some music, and writing. Glenn did level design, game design, QA, and loads of playtesting. He has probably played more Danger Crew battles than anybody on Earth.
          Most of this took place between Fall 2015 to our release in May 2019. We still make updates.
        </p>
        <p>Danger Crew includes some original music written and recorded by <strong>Henry Leacock</strong>.</p>
        <p>The original concept for Danger Crew was created by <strong>Henry Leacock</strong>, <strong>David Stout</strong>, and <strong>Drew Conley</strong>.</p>

        <h3>We had a ton of help from many friends along the way!</h3>
        <p style={{fontSize: 14, color: "#444", textTransform: "uppercase", fontWeight: "bold", margin: "0 0 1em 0"}}>( Drew writing the notes below )</p>
        <p>
          <strong>AJ Manker</strong> and <strong>Mollie Blackwell</strong> helped us shift our artwork over to full pixel art style. They coached me in my slow, painful, and ongoing journey of learning to make pixel art. They are two of the best designers I've ever known. <strong>Brett Banning</strong> made some assets and did some great design work for our demo. He's also one of the greatest designers I know!
        </p>
        <p>
          <strong>Jacob White</strong> was my manager for many years. He's perhaps the most supportive and brilliant person I've ever met. He helped me push through a lot of bummers (he still does today!) and inspired us stay motivated to complete the game. He even wrote the dialog lines for his own character!
        </p>

        <p>
          <strong>Another mega thanks</strong> to all of the amazing players that helped us shape the game via feedback over the years. If you're in the middle of making a game, stick with it!
        </p>

        <div style={{marginTop: "2em"}}>
          <Link style={{background: "#5abcff", color: "#fff", padding:15, textDecoration: "none", borderRadius: 3, borderBottom: "3px solid #5e9ef5"}} to="/">
            Back to Home
          </Link>
        </div>
      </div>

    </main>
  </Layout>
);

export default CreditsPage
